// languages
export const SK = 'SK';
export const ROM = 'ROM';

export enum DatabasePath {
  SK = 'languages/slovak/lemmas',
  ROM = 'languages/romani/lemmas',
}

export enum Sources {
  'ACR' = 'Atlas of Central Romani. Dostupné na: http://atlasofcentralromani.ff.cuni.cz/#/',
  'Aichele 1957' = 'Aichele, W. (1957). Die ursprünglich aspirierten Medien der Zigeunersprache. In: Zeitschrift für Phonetik und allgemeine Sprachwissenschaft. Band 10. Heft 2. pp. 101-107.',
  'Beekes EDG 2010' = 'Beekes, R. S. P. (2010). Etymological Dictionary of Greek. Leiden – Boston: Brill.',
  'Beníšek 2006' = 'Beníšek, M., (2006). Ke kořenům slova ROM. In: Romano džaniben, jevend 2006: 9–27.',
  'Beníšek 2008' = 'Beníšek, M. (2008). Poznámky k protoromštině/Notes on Proto-Romani. Diplomová práce (Univerzita Karlova, Praha).',
  'Beníšek 2013a' = 'Beníšek, M. 2013. Central Romani lidža-/ledž-: a vestige of an Indo-Aryan compound verb and its cross-dialectal variability. Acta Orientalia Academiae Scientiarum Hungaricae 66. 4: 471–486.',
  'Beníšek 2013b' = 'Beníšek, M.2013. Predicative possession in Western Transcarpathian Romani.7th International Conference on Language Variation in Europe (ICLaVE).Trondheim. 26–28 June 2013',
  'Beníšek 2020' = 'Beníšek, M. (2020).The historical origins of Romani.In: Matras, Y., Tenser, A., The Palgrave handbook of Romani language and linguistics.Palgrave Macmillan Ltd.',
  'Boretzky 1995' = 'Boretzky, N. (1995).Armenisches im Zigeunerischen(Romani und Lomavren).In: Indogermanische Forschungen, pp. 137 - 155.',
  'Boretzky 2012' = 'Boretzky, N. (2012).Studien zum Wortschatz des Romani: I.Erbwörter des Romani; II.Iranismen und Armenismen des Romani.Veliko Tărnovo: Faber.',
  'Boretzky, Igla 2004' = 'Boretzky, N., Igla, B. (2004).Kommentierter Dialektatlas des Romani.Wiesbaden: Harrassowitz.',
  'Courthiade 2007' = 'Courthiade, M. (2007).Sagesse et humour du peuple rrom: Sar o rromano ilo, nanaj p - i sasti phuv.Paríž: L’Harmattan.',
  'Courthiade 2009' = 'Courthiade, M. (2009).Morri angluni rromane ćhibaqi evroputni lavustik.Budapešť: Romano kher.',
  'Čalo voďi' = 'Kramářová, J. - Sadílková, H. (Ed.). (2007).Čalo voďi / Sytá duše.Antologie prozaických textů romských autorů z ČR.Brno: Muzeum romské kultury.',
  'Demeterová 1994' = 'Demeterová, H. (1994).Rom ke Romeste drom arakhel / Rom k Romovi cestu najde.Praha: Sdružení romských autorů - ROMAŇI ČHIB.',
  'Elšík 2009' = "Elšík, V. (2009).Loanwords in Selice Romani, an Indo - Aryan language of Slovakia.In: Loanwords in the world's languages: A comparative handbook. Berlin: Mouton De Gruyter. pp. 1-76.",
  'Fabiánová 1992' = 'Fabiánová, T. (1992).Sar me phiravas andre škola / Jak jsem chodila do školy.Nakladatelství a vydavatelství ÚDO České Budějovice ve spolupráci se Společenstvím Romů na Moravě.',
  'Fabiánová, Hübschmannová 1991' = 'Fabiánová, T., Hübschmannová, M. (1991).Čavargoš / Tulák.Praha: Apeiron.',
  'Ferková 1992' = 'Ferková, I. (1992).Mosarďa peske o dživipen.Praha: Romaňi čhib.',
  'Ferková 1996' = 'Ferková, I. (1996).Čorde čhave / Ukradené děti.Společenství Romů na Moravě.',
  'Ferková 2018' = 'FERKOVÁ, I. (2018).Ještě jedno, Lído! Kaštánkovy příběhy z herny./ De mek jekh, Ľido! Kaštankuskre vakeribena andal e herňa.Praha: KHER.',
  'Friedman 1988' = 'Friedman, V., A. (1988).A Caucasian loanword in Romani.In: DeSilva, Cara, Grumet, Joanne & Nemeth, David J. (Eds.) Papers from the eighth and ninth annual meetings, Gypsy Lore Society, North American Chapter.New York: Gypsy Lore Society, North American Chapter. 18–20',
  'Giňa 2013' = 'Giňa, A. (2013).PAŤIV Ještě víme, co je úcta, Praha: Triáda.',
  'Goďaver lava' = 'Hübschmannová, M. (1991).Goďaver lava phure Romendar / Moudrá slova starých Romů.Praha: Aperion.',
  'Haluška 2003' = 'Haluška, V. (2003).Pal le Devleskero Sidorkus / O božím Sidorkovi.Praha: Signeta.',
  'Hancock 1988' = 'Hancock, I. (1988).The development of Romani Linguistics.In: Jazayrey, M., A., Winter, W. (1988).Language and Cultures.Studies in Honor of Edgar C.Polomé.Berlin - New York - Amsterdam: Mouton de Gruyter.',
  'Horváthová A. 2003' = 'Horváthová, A. (2003).Pal e Bari Rama the aver paramisa / O Velké Rámě a jiné příběhy.Praha: Signeta.',
  'Hübschmannová 1993' = 'Hübschmannová, M. (1993).Šaj pes dovakeras / Můžeme se domluvit. 1. vyd., Olomouc: Pedag.fakulta UP.',
  'IEW' = 'Pokorny, J. (1959–1969).Indogermanisches etymologisches Wörterbuch.Bern – München: Francke.',
  'Kent 1953' = 'Kent, R., G. (1953).Old Persian.Grammar.Texts.Lexicon.Second edition.New Haven: American Oriental Society.',
  'Kováčová V.2021' = 'Kováčová, V. (2021).Sunoro – Sen.OZ Cesta von.',
  'Králik SESS 2015' = 'Králik, Ľ. (2015).Stručný etymologický slovník slovenčiny.Bratislava: VEDA.',
  'Králik SESS 2019' = 'Králik, Ľ. (2019).Stručný etymologický slovník slovenčiny.Bratislava: VEDA.',
  'Lacková 1992' = 'Lacková, E. (1992).Rómske rozprávky(Romane paramisa).Košice: Východoslovenské vydavateľstvo Košice.',
  'Lacková 1997' = 'Lacková, E. (1997).Narodila jsem se pod šťastnou hvězdou.Praha: Triáda.',
  'Lacková 1999' = 'Lacková, E. (1999).Romane paramisa / Romské pohádky.Praha: Radix.',
  'LIV2' = 'Rix, H., Kümmel, M., Zehnder, TH., Lipp, R., Schirmer, B. (2001).Lexikon der indogermanischen Verben.Die Wurzeln und ihre Primärstammbildungen.Wiesbaden: Reichert.',
  'Lundgrenová, Taikonová, Erikssonová' = 'Lundgrenová, G., Taikonová, S., Erikssonová, A. (2022).Žofi Z - 4515. Praha: KHER.',
  'Mānuš 1994' = 'Mānuš, L. (1994). “Le etimologie di venti vocaboli zingari.” Lacio Drom 30: 26–34.',
  'Mānuš 1997' = 'Mānušs, L., Neilands, J. & Rudevičs, K. (1997).Čigānu–latviešu–angļu etimoloģiskā vārdnīca un latviešu–čigānu vārdnīca. [Gypsy–Latvian–English etymological dictionary and Latvian–Gypsy dictionary.]Rīgā: Zvaigzne ABC.',
  'Masica 1991' = 'Masica, C., P. (1991).The Indo - Aryan languages.Cambridge: Cambridge University Press.',
  'Matras 2002' = 'Matras, Y. (2002).Romani: A linguistic introduction.Cambridge: Cambridge University Press.',
  'Mayrhofer EWA I.' = 'Mayrhofer, M. (1992).Etymologisches Wörterbuch des Altindoarischen.I.Band.Heidelberg: Carl Winter.',
  'Mayrhofer EWA II.' = 'Mayrhofer, M. (1996).Etymologisches Wörterbuch des Altindoarischen.II.Band.Heidelberg: Carl Winter',
  'Mayrhofer EWA III.' = 'Mayrhofer, M. (2001).Etymologisches Wörterbuch des Altindoarischen.III.Band.Heidelberg: Carl Winter.',
  'Moji milí' = 'Berkyová, R. - Houdek, L. - Patočková, R. (Ed.). (2014).Moji milí - sbírka romské narativní prózy.Praha: KHER.',
  'Nástin mluvnice 1998' = 'Šebková, H., Žlnayová, E. (1998).Nástin mluvnice slovenské romštiny(pro pedagogické účely), Pedagogická fakulta UJEP v Ústí n.L..',
  'Nyberg 1974' = 'Nyberg, H., S. (1974).A Manual of Pahlavi II.Wiesbaden: Otto Harrassowitz.',
  'O mulo!' = 'Ryvolová, K. (Ed.). (2019).O mulo! Povídky o duchách zemřelých.Sbírka romských autorů.Praha: KHER.',
  'Oláh 2002' = 'Oláh, V. (2002).Khamori luluďi / Slunečnice - Romská poezie.Praha: Radix.',
  'Oláh 2003' = 'Oláh, V. (2003).Le khameskere čhave / Děti slunce.Praha: Matice romská.',
  'Oláh 2005' = 'Oláh, V. (2005).Khamutno kamibe / Žár lásky.Praha: Matice romská, o.s.sdružení Dženo.',
  'Oláh 2006' = 'Oláh, V. (2006).Amaro drom pal o Udut / Naše cesta za Světlem.Praha: Matice romská.',
  'Oslon-Kožanov ESCJ' = 'Oslon, M., Kožanov, K. (2023).Этимологический словарь цыганского языка(общецыганская лексика) / La řomańa ćhiba - ka etimologija - ko alavari(o sa - řomano leksiko).Dostupné na: rromanes.org / pub / ЭСЦЯ.pdf',
  'Otcův duch' = 'Hlaváčková, I., Houdek, L., Patočková, R. (Ed.). (2012).Otcův duch a jiné pohádky romských autorů.Praha: KHER.',
  'Pay źuvindó' = 'Demetri, M., Dimiter - Taikon, A. (2002).Pay źuvindó, Romané sityarimáta hay divánóri.Štokholm: Skolverket.',
  'PHRLL' = 'Matras, Y., Tenser, A. (2020).The Palgrave handbook of Romani language and linguistics.Palgrave Macmillan Ltd.',
  'PRP 2006' = 'Hübschmannová, M.a kol. (2006).Pravidlá rómskeho pravopisu.Bratislava: Štátny pedagogický ústav.',
  'RČČRKS' = 'Hübschmannová, M., Šebková, H., Žigová, A. (2001).Romsko - český a česko - romský kapesní slovník.Praha: Fortuna.',
  'RČH 2002a' = 'Šebková, H. - Žlnayová, E. (2002).Romaňi čhib.Učebnica rómčiny(severocentrálny dialekt).Košice: BOHEMIA KONTAKT.',
  'RČH 2002b' = 'Šebková, H. - Žlnayová, E. (2002).Romaňi čhib.Kľúč k učebnici rómčiny(severocentrálny dialekt).Košice: BOHEMIA KONTAKT.',
  'RČH 2007' = 'Gašparová, E., Koptová, A., Lukáčová, I., (2007).Romaňi čhib – učebnica rómskeho jazyka, Štátny pedagogický ústav, Bratislava 2007.',
  'RDV' = 'Koptová, A. (1995).Rómčina do vrecka.Košice: Pezolt.',
  'RDŽ' = 'Romano Džaniben - časopis romistických studií.Praha: Společnost přátel časopisu Romano džaniben.Dostupné na: https://www.dzaniben.cz/',
  'Reiznerová 1994' = 'Reiznerová, M. (1994).Kaľi.Praha: Sdružení romských autorů - ROMAŇI ČHIB.',
  'Rejzek ČES 2015' = 'Rejzek, J. (2015).Český etymologický slovník.Třetí vydání(druhé přepracované a rozšířené vydání).Praha: Leda.',
  'Romano suno' = 'Romano suno – Romský sen.Sborníky vítězných prací.Dostupné na: http://www.novaskolaops.cz/sborniky-viteznych-praci',
  'ROMLEX' = 'ROMLEX Lexical Database(Lexikálna databáza rómskych dialektov).Dostupné na: http://romani.uni-graz.at/romlex/lex.xml',
  'Romské dějiny' = 'Hübschmannová, M. (1998 - 2002).Romaňi historia – Romské dějiny.In: Amaro gendalos.Praha: Sdružení Dženo.',
  'Romské hádanky' = 'Hübschmannová, M. (Ed.)(2003).Hin man ajsi čhaj, so... Romské hádanky.Praha: Fortuna.',
  'RSFS' = 'Horňáková, M. (2020).Rómsko - slovenský frazeologický slovník, Košice: Nadácia Dobrá rómska víla Kesaj.',
  'Samet blues' = 'Jandáková, L. (Ed.). (2021).Samet blues.Praha: KHER.',
  'Sampson 1926' = 'Sampson, J. (1926).The dialect of the Gypsies of Wales, being the older form of British Romani preserved in the speech of the clan of Abram Wood.Repr. 1968. Oxford: Clarendon Press.',
  'Soravia 1988' = 'Soravia, G. (1988).Di alcune etimologie zingariche.In: Archivio glottologico italiano 73, pp. 3–11.',
  'SRN' = 'Slovník romských neologismů(Seminář romistiky Katedry středoevropských studií FF UK, Praha).Dostupné na: https://romstina.ff.cuni.cz/srn/index_srn.php',
  'SRRSS 1996' = 'Berky, J., Prokop, J., Stojka, M. (1996) Slovensko - rómsky rómsko - slovenský slovník.Bratislava: Štúdio dd.',
  'SRRSS 2011' = 'Koptová, A., Koptová, M. (2011).Slovensko - rómsky rómsko - slovenský slovník.Košice: Nadácia Dobrá rómska víla Kesaj.',
  'SRTS' = 'Cina, S.a kol. (2012).Slovensko - rómsky terminologický slovník.Bratislava: Úrad splnomocnenca vlády slovenskej republiky pre národnostné menšiny.Dostupné na: https://www.narodnostnemensiny.vlada.gov.sk/odborne-terminologicke-slovniky-v-jazykoch-narodnostnych-mensin/',
  // 'Ščuka – Hitmejkri' = 'Ščuka, E.Hitmejkri – Bare hirešne lavutara.Praha: Mezinárodní konzervatoř Praha, Muzeum romského národního obrození.',
  // 'Ščuka – O majstri' = 'Ščuka, E.O majstri – ašunde bare manuša andro klasicko bašaviben the andro jazzos.Praha: Mezinárodní konzervatoř Praha, Muzeum romského národního obrození.',
  // 'Ščuka – Uľile' = 'Ščuka, E. (2020).Uľile le vonoha andro vasta – Nekhašundeder romane lavutara andre Československo.Praha: Muzeum romského národního obrození.',
  // 'Ščuka 2020a' = 'Ščuka, E. (2020) Devla miro, sem jov hino Rom! Praha: Knihovna Václava Havla.',
  // 'Ščuka 2020b' = 'Ščuka, E. (2020) Me som Rom romano.Praha: Knihovna Václava Havla.',
  // 'Ščuka 2020c' = 'Ščuka, E. (2020) Jekh čiriklo či kerel ňilaj.Praha: Knihovna Václava Havla.',
  'Šebková 2023' = 'Šebková, H. (2003).Bacht the balvaj pheňa hin / Štěstí a vítr jedno jsou.Příchovice: BUK.',
  'Tálos 1999' = 'Tálos, E. (1999).Etymologica Zingarica.In: Acta Linguistica Hungarica.Vol. 46(3 - 4).pp. 215 - 268.',
  'Tsabolov 2010' = 'Tsabolov(2010).Etimologičeskij slovar kurdskogo jazyka.TOM II N - Ž.Moskva: Firma.',
  'Turner 1924' = 'Turner, R., L. (1924).traš - ‘to frighten’. Journal of the Gypsy Lore Society, Third series 3: 38–43.',
  'Turner CDIAL 1966' = 'Turner, R.L. (1966).A comparative dictionary of the Indo - Aryan languages.London: Oxford University Press.',
  'van den Heuvel 2020' = 'van den Heuvel, W.Romani Bible Translation and the Use of Romani in Religious Contexts.In: Matras, Y., Tenser, A., The Palgrave handbook of Romani language and linguistics.Palgrave Macmillan Ltd.',
  'Vekerdi 2002' = 'Vekerdi, J.with the assistance of Zsuzsa Várnai. (2002).A comparative dictionary of Gypsy dialects in Hungary.Gypsy - English - Hungarian dictionary with English to Gypsy and Hungarian to Gypsy word lists.Budapest: Terebess Publications. [revised edition of Vekerdi 1983].',
  'VRP' = 'Výklad romského pravopisu(Seminář romistiky Katedry středoevropských studií FF UK, Praha).Dostupné na: https://romstina.ff.cuni.cz/srn/index_srn.php',
}
