import React, { useContext } from 'react';
import './Definitions.css';
import LanguageContext from '../../context/LanguageContext';
import { ROM, SK } from '../constants';
import DefinitionsSK from './DefinitionsSK';
import DefinitionsROM from './DefinitionsROM';
import { Helmet } from 'react-helmet'
import { DocumentData } from 'firebase/firestore/lite';



const Definitions = ({ signs }: { signs: { id: string; data: DocumentData }[] }) => {
	const { selectedLanguage } = useContext(LanguageContext);	

	console.log(signs)

	return (
		<>
		<Helmet>
			<title>{signs[0].data.sign}</title>
			<meta name="description" content={`Preklady, definície a príklady používania slov ${signs.map((sign) => `"${sign.data.sign}"`)} v rómskom jazyku.`} />
		</Helmet>
			<div className="signs">
				{selectedLanguage === SK && (<DefinitionsSK signs={signs} />)}
				{selectedLanguage === ROM && (<DefinitionsROM signs={signs} />)}
			</div>
		</>
	);
};

export default Definitions;
