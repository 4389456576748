import { useCallback, useState } from 'react'
import React from 'react'

import Search from "../components/Search/Search";
import Definitions from "../components/Definitions/Definitions";
import { DocumentData } from 'firebase/firestore/lite';
import { LanguageProvider } from '../context/LanguageContext';


const Home = () => {
	const [signs, setSigns] = useState<{ id: string; data: DocumentData }[]>([])

	const onSetSigns = useCallback((signs: { id: string; data: DocumentData }[]) => {
		setSigns(signs)
	}, [])

	return (
		<LanguageProvider>
			<Search handleSetSigns={onSetSigns} />
			{signs?.length > 0 && <Definitions signs={signs} />}
		</LanguageProvider>

	)
}

export default Home
