import React from 'react'
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import kultMinorLogo from '../images/kult-minor-logo.png';
import kesajLogo from '../images/kesaj-logo.jpg';

const ONas = () => {
	return (
		<div >
			<h2>
				Milí návštevníci našej stránky,
			</h2>
			<p style={{ lineHeight: "140%" }}>
				predstavujeme vám našu online verziu slovensko-rómskeho slovníka. V súčasnosti slovník obsahuje viac ako 10 000 hesiel. Východiskom pre heslár sa stal Frekvenčný slovník hovorenej slovenčiny na báze Slovenského hovoreného korpusu (Gajdošová, Šimková, VEDA, Bratislava 2018). Heslár bol priebežne dopĺňaný aj z ďalších zdrojov. Slovná zásoba rómskeho jazyka bola do online slovníka čerpaná najmä z publikácie Slovensko-rómsky rómsko-slovenský slovník (Koptová, A., Koptová, M., Nadácia Dobrá rómska víla Kesaj 2011). K tvorbe slovníka výrazne prispel výskum slovnej zásoby rómskeho jazyka, ktorý bol v roku 2021 finančne podporený Fondom na podporu kultúry národnostných menšín. V slovníku rešpektujeme kodifikovaný pravopis rómčiny (tzv. severocentrálny dialekt). Najnovší výklad pravopisných pravidiel nájdete uverejnený na webovej stránke: <Link href="https://romstina.ff.cuni.cz/rp/index.php" underline="none">https://romstina.ff.cuni.cz/rp/index.php</Link>
			</p>


			<p style={{ lineHeight: "140%" }}>
				Online slovník postupne rozširujeme, pridávame ďalšie heslá a dopĺňame tie, ktoré už v slovníku sú. Budeme radi, ak nám s tým pomôžete. Svoje doplnenia, opravy a pripomienky nám môžete posielať na emailovú adresu: <Link href="mailto:romskyslovnik.online@gmail.com" underline="none">romskyslovnik.online@gmail.com</Link>
			</p>
			<p style={{ fontSize: "11px" }}>
				<b>Autorský tím</b>: Anna Koptová, Martina Horňáková, Iveta Kokyová, Martin Gális, František Eku Mižigár
			</p>
			<p style={{ fontSize: "11px" }}>
				<b>Programovanie a design stránky</b>: Dávid Merényi
			</p>
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<p>
						Realizované s finančnou podporou Fondu na podporu kultúry národnostných menšín
					</p>
					<img src={kultMinorLogo} alt="logo Fondu na podporu kultúry národnostných menšín" style={{ width: "50%" }} />
				</Grid>
				<Grid item xs={12} md={6}>
					<p>
						Vznik online slovníka podporila: Nadácia Dobrá rómska víla Kesaj
					</p>
					<img src={kesajLogo} alt="logo rómskej nadácie Kesaj" style={{ height: "50%" }} />
				</Grid>
			</Grid>
		</div >
	)
}

export default ONas
