import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: 'AIzaSyBxyLGqEFiIWgCytVj-BBk_4hgiujCqv6g',
	authDomain: 'slovnik-714c9.firebaseapp.com',
	projectId: 'slovnik-714c9',
	storageBucket: 'slovnik-714c9.appspot.com',
	messagingSenderId: '686972684969',
	appId: '1:686972684969:web:5d29ab9d34c45a992c77b8',
	measurementId: "G-D2B5EN7QVB"
}

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const db = getFirestore(app);


